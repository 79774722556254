import './Table.scss';
import {TableRow} from "./TableRow/TableRow";
import axios from "axios";
import {useEffect, useState} from "react";

const step = 10;

export const Table = () => {

    const [rows, setRows] = useState([]);
    const [totalMinted, setTotalMinted] = useState(null);
    const [received, setReceived] = useState(null);
    const [rowsInView, setRowsInView] = useState(step);

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_PUBLIC_API}/get_all_user`, {
            auth: {
                username: process.env.REACT_APP_PUBLIC_LOG,
                password: process.env.REACT_APP_PUBLIC_PASS
            }
        })
            .then(({data}) => {
                setRows(data.reverse())

                let total = 0;

                data.filter(x => x.status === 'confirm').forEach((x) => total += Number(x.amount))

                setTotalMinted(total)
            })

        axios.get('https://hiddenadmin.chebulucky.com/amount').then(({data}) => setReceived(data['Total BTC Recieve']))
    }, [])

    const list = rows.slice(0, rowsInView).map((row, i) => {
        return (
            <TableRow
                number={i + 1}
                row={row}
                key={i}
            />
        )
    })

    return (
        <section className={'grid'}>
            <div className="container">

                <div className={'grid__result-wrap'}>
                    {totalMinted >= 0 && <div className={'bold grid__total'}>Minted: {totalMinted}</div>}
                    {received >= 0 && <div className={'bold'}>Total received: {received} BTC</div>}
                </div>

                <div className="grid__list">

                    <TableRow disabled={true}/>

                    {list.length ? (
                        <>
                            {list}
                            {rows.length > list.length && (
                                <div
                                    className={'grid__btn btn btn-primary'}
                                    onClick={() => setRowsInView(rowsInView => rowsInView + step)}
                                >
                                    Show More
                                </div>
                            )}
                        </>
                    ) : (
                        'Loading'
                    )}

                </div>
            </div>
        </section>
    )
}