import './App.scss';
import {Auth} from "./components/Auth/Auth";
import {useEffect, useState} from "react";
import {Table} from "./components/Table/Table";

function App() {

    const [isAuth, setIsAuth] = useState(false);

    useEffect(() => {
        setIsAuth(sessionStorage.getItem('auth') === 'true')
    }, [])

    return (
        <div>
            {isAuth ? <Table/> : <Auth setIsAuth={setIsAuth}/>}
        </div>
    );
}

export default App;
