import './Auth.scss';
import {useState} from "react";
import axios from "axios";

const initialInputs = {
    login: '',
    pass: ''
}

export const Auth = ({setIsAuth}) => {

    const [inputs, setInputs] = useState(initialInputs);

    const inputsHandler = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(inputs => ({...inputs, [name]: value}))
    }

    const onLoginClick = (event) => {

        const pass = inputs.pass.replace('#', '%23');
        const rightPass = pass.replace('@', '%40');

        event.preventDefault();
        axios.post(`${process.env.REACT_APP_PUBLIC_API}/autorization?login=${inputs.login}&password=${rightPass}`)
            .then((res) => {
                setIsAuth(res.data.Status)
                sessionStorage.setItem('auth', `${res.data.Status}`)
            })
            .catch((error) => {
                sessionStorage.setItem('auth', `false`)
                console.log(error)
            })
    }

    return (
        <section className={'auth'}>
            <div className="container">
                <form>
                    <div className="mb-3">
                        <label className="form-label">
                            Login
                            <input
                                type="text"
                                name={'login'}
                                className="form-control"
                                value={inputs.login}
                                onChange={inputsHandler}
                            />
                        </label>
                    </div>
                    <div className="mb-3">
                        <label className="form-label">
                            Password
                            <input
                                type="password"
                                name={'pass'}
                                className="form-control"
                                value={inputs.pass}
                                onChange={inputsHandler}
                            />
                        </label>
                    </div>

                    <button onClick={onLoginClick} type="submit" className="btn btn-primary">Login</button>
                </form>
            </div>
        </section>
    )
}
