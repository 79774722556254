import './TableRow.scss';
import {useEffect, useState} from "react";
import axios from "axios";

// const status = ['pending', 'confirm'];

const nftPrice = 500000;
const fee = 150000;
const authData = {
    username: process.env.REACT_APP_PUBLIC_LOG,
    password: process.env.REACT_APP_PUBLIC_PASS
}

export const TableRow = ({row, number, disabled}) => {

    const [isClaimActive, setIsClaimActive] = useState(row?.status === 'confirm');
    const [transactions, setTransactions] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isDone, setIsDone] = useState(row?.result);
    const [balance, setBalance] = useState(null);

    const onClaimClick = () => {
        if (disabled) return;
        const totalPrice = row.amount * nftPrice;
        const totalFee = row.amount * fee;

        if (row?.ref === 'None') {
            axios.post(`${process.env.REACT_APP_PUBLIC_API}/transfer?source_k=${row.private_key}&amount=${totalPrice}&amount_fee=${totalFee}`, {}, {
                auth: authData
            })
                .then(() => {
                    axios.post(`${process.env.REACT_APP_PUBLIC_API}/result?id_user=${row._id}`, {}, {
                        auth: authData
                    })
                        .then(() => setIsDone(true))
                })
                .catch(error => console.log(error))
        }
        //if have referrals
        // else {
        //     axios.get(`${process.env.REACT_APP_PUBLIC_API}/get_ref?ref_link=${row.ref}`, {
        //         auth: {
        //             username: process.env.REACT_APP_PUBLIC_LOG,
        //             password: process.env.REACT_APP_PUBLIC_PASS
        //         }
        //     })
        //         .then(({data}) => {
        //             const btcWallet = data[0].btc_wallet;
        //             axios.post(`${process.env.REACT_APP_PUBLIC_API}/transfer_cashback?source_k=${row.private_key}&ref_wallet=${btcWallet}&amount_owner=${totalPrice / 100 * 90}&amount_refferal=${totalPrice / 100 * 10}&amount_fee=${totalFee}`, {}, {
        //                 auth: authData
        //             })
        //                 .then(() => {
        //                     axios.post(`${process.env.REACT_APP_PUBLIC_API}/result?id_user=${row._id}`, {}, {
        //                         auth: authData
        //                     })
        //                         .then(() => setIsDone(true))
        //                 })
        //         })
        // }
    }

    useEffect(() => {
        if (!disabled && !isDone) {
            axios.get(`${process.env.REACT_APP_PUBLIC_API}/check?private_key=${row.private_key}`, {
                auth: authData
            })
                .then(({data}) => {
                    const balance = data[0]

                    if (balance >= (row.amount * nftPrice)) {
                        return axios.post(`${process.env.REACT_APP_PUBLIC_API}/change_status?id_user=${row._id}`)
                            .then(() => {
                                setIsClaimActive(true)
                                setBalance(balance)
                            })
                    } else if (balance > 0) {
                        setBalance(balance)
                    }
                })
                .catch(err => console.log(row._id, err))
                .finally(() => setIsLoading(false))
        } else {
            setIsLoading(false)
        }
    }, [])

    const checkTransaction = () => {
        if (disabled) return;

        axios.get(`${process.env.REACT_APP_PUBLIC_API}/check_transaction?source_k=${row.private_key}`, {
            auth: authData
        })
            .then(({data}) => {
                if (data) {
                    setTransactions(data)
                }
            })
    }

    let ethWallet;
    let createAt;
    ethWallet = row?.eth_wallet

    if (!disabled) {
        const day = new Date(row.create_at).getDate() < 10 ? `0${new Date(row.create_at).getDate()}` : new Date(row.create_at).getDate();
        const month = new Date(row.create_at).getMonth() + 1 < 10 ? `0${new Date(row.create_at).getMonth() + 1}` : new Date(row.create_at).getMonth() + 1;
        const hours = new Date(row.create_at).getHours() < 10 ? `0${new Date(row.create_at).getHours()}` : new Date(row.create_at).getHours()
        const minutes = new Date(row.create_at).getMinutes() < 10 ? `0${new Date(row.create_at).getMinutes()}` : new Date(row.create_at).getMinutes()
        createAt = `${day}.${month} ${hours}:${minutes}`;
    }

    return (
        <div className={`table-row ${isDone ? 'table-row_done' : ''}`}>

            {isLoading ? 'Loading...' : (
                <>
                    <div className="table-row__num">{disabled ? '#' : number}</div>

                    <div className="table-row__wallet">
                        {disabled ? '' : (
                            <>
                                <div className="table-row__wallet_ordinal"><span className={'bold'}>Ord</span> <input
                                    value={row.ordwallet} readOnly={true}/></div>
                                <div className="table-row__wallet_eth"><span className={'bold'}>Eth</span> <input
                                    value={ethWallet} readOnly={true}/></div>
                            </>
                        )}
                    </div>

                    <div className="table-row__date">{disabled ? 'Date' : createAt}</div>

                    <div className="table-row__amount">{disabled ? 'Amount' : row.amount}</div>

                    {/*<div className="table-row__ref">{disabled ? 'Referral' : row.ref}</div>*/}

                    <div onClick={checkTransaction} className="table-row__tx">

                        {disabled ? 'Tx' : (
                            <>
                                Tx
                                {transactions.length > 0 && (
                                    <div onClick={(event) => {
                                        event.stopPropagation()
                                        setTransactions([])
                                    }}>
                                        {transactions.map((tx) => {
                                            return (
                                                <p
                                                    key={tx}
                                                    onClick={(event) => event.stopPropagation()}
                                                >
                                                    {tx}
                                                </p>
                                            )
                                        })}
                                    </div>
                                )}
                            </>
                        )}

                    </div>

                    <div className="table-row__status">{disabled ? 'Status' : row.status}</div>

                    <div
                        onClick={onClaimClick}
                        className={`table-row__claim-button ${isClaimActive ? 'table-row__claim-button_active' : ''}`}
                    >
                        {!isDone ? 'Claim' : 'Claimed'}
                        {/*Satoshi to BTC*/}
                        {balance && <span>{(balance / 100000000).toString()}</span>}
                    </div>
                </>
            )}

        </div>
    )
}